// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Control from "https://framerusercontent.com/modules/orsb9uZe25YHvpQLUNxF/N2iKoPb0QLAArpndFbCZ/V1I50TOaE.js";
import Photos from "https://framerusercontent.com/modules/sQBfEfqnukW8HKL4Oby3/tQ5vP4qJ1cwYWgsnFpwi/TO_TFA1pG.js";
import * as localizedValues from "./ZXPLkcF9z-0.js";
const ControlFonts = getFonts(Control);
const PhotosFonts = getFonts(Photos);

const cycleOrder = ["VDF8QfLfh", "H74JxqKqp", "FSX7mqUMx"];

const serializationHash = "framer-6MFbf"

const variantClassNames = {FSX7mqUMx: "framer-v-1k1w5rl", H74JxqKqp: "framer-v-ydtslf", VDF8QfLfh: "framer-v-13mb70c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Slide 1": "VDF8QfLfh", "Slide 2": "H74JxqKqp", "Slide 3": "FSX7mqUMx"}

const getProps = ({height, id, item1, item2, item3, photo1, photo2, photo3, width, ...props}) => { return {...props, aXUMjUfzq: photo2 ?? props.aXUMjUfzq, GnCinwaYh: item3 ?? props.GnCinwaYh ?? "Cabin", h0RK_sc62: item1 ?? props.h0RK_sc62 ?? "Snow", k4U5vm2cy: item2 ?? props.k4U5vm2cy ?? "Lake", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "VDF8QfLfh", xpBsZprVq: photo3 ?? props.xpBsZprVq, yQxlx3dRA: photo1 ?? props.yQxlx3dRA} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;photo1?: {src: string; srcSet?: string};photo2?: {src: string; srcSet?: string};photo3?: {src: string; srcSet?: string};item1?: string;item2?: string;item3?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, yQxlx3dRA, aXUMjUfzq, xpBsZprVq, h0RK_sc62, k4U5vm2cy, GnCinwaYh, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "VDF8QfLfh", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearrabh3c = activeVariantCallback(async (...args) => {
await delay(() => setVariant("H74JxqKqp"), 2000)
})

const onAppearohhguu = activeVariantCallback(async (...args) => {
await delay(() => setVariant("FSX7mqUMx"), 2000)
})

const onAppear159y4qv = activeVariantCallback(async (...args) => {
await delay(() => setVariant("VDF8QfLfh"), 2000)
})

const XYwhqTqPSzqumy2 = activeVariantCallback(async (...args) => {
setVariant("VDF8QfLfh")
})

const PrY8IGY1j15i2xfi = activeVariantCallback(async (...args) => {
setVariant("H74JxqKqp")
})

const PWDH3r3Wc1re44y9 = activeVariantCallback(async (...args) => {
setVariant("FSX7mqUMx")
})

useOnVariantChange(baseVariant, {default: onAppearrabh3c, FSX7mqUMx: onAppear159y4qv, H74JxqKqp: onAppearohhguu})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-13mb70c", className, classNames)} data-framer-name={"Slide 1"} data-highlight layoutDependency={layoutDependency} layoutId={"VDF8QfLfh"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({FSX7mqUMx: {"data-framer-name": "Slide 3"}, H74JxqKqp: {"data-framer-name": "Slide 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1fehphz-container"} layoutDependency={layoutDependency} layoutId={"kJQGg3ALv-container"}><Control eBdPxWrxk={h0RK_sc62} height={"100%"} id={"kJQGg3ALv"} layoutId={"kJQGg3ALv"} OFd9cLEOQ={k4U5vm2cy} PrY8IGY1j={PrY8IGY1j15i2xfi} PWDH3r3Wc={PWDH3r3Wc1re44y9} Rh2gvBOVT={GnCinwaYh} style={{width: "100%"}} variant={"AVFqF8pC8"} width={"100%"} XYwhqTqPS={XYwhqTqPSzqumy2} {...addPropertyOverrides({FSX7mqUMx: {variant: "l9LtVXNVk"}, H74JxqKqp: {variant: "ONIjzkIon"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-14hdlgz-container"} layoutDependency={layoutDependency} layoutId={"tKO638M7Z-container"}><Photos height={"100%"} id={"tKO638M7Z"} layoutId={"tKO638M7Z"} photo1={toResponsiveImage(yQxlx3dRA)} photo2={toResponsiveImage(aXUMjUfzq)} photo3={toResponsiveImage(xpBsZprVq)} style={{height: "100%", width: "100%"}} variant={"BI1hFz2Os"} width={"100%"} {...addPropertyOverrides({FSX7mqUMx: {variant: "dl0WVQ5Z2"}, H74JxqKqp: {variant: "mwiM0bWmw"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6MFbf.framer-10qo0f1, .framer-6MFbf .framer-10qo0f1 { display: block; }", ".framer-6MFbf.framer-13mb70c { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 490px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 600px; }", ".framer-6MFbf .framer-1fehphz-container { flex: none; height: auto; position: relative; width: 100%; }", ".framer-6MFbf .framer-14hdlgz-container { flex: 1 0 0px; height: 1px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6MFbf.framer-13mb70c { gap: 0px; } .framer-6MFbf.framer-13mb70c > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-6MFbf.framer-13mb70c > :first-child { margin-top: 0px; } .framer-6MFbf.framer-13mb70c > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 490
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"H74JxqKqp":{"layout":["fixed","fixed"]},"FSX7mqUMx":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"yQxlx3dRA":"photo1","aXUMjUfzq":"photo2","xpBsZprVq":"photo3","h0RK_sc62":"item1","k4U5vm2cy":"item2","GnCinwaYh":"item3"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZXPLkcF9z: React.ComponentType<Props> = withCSS(Component, css, "framer-6MFbf") as typeof Component;
export default FramerZXPLkcF9z;

FramerZXPLkcF9z.displayName = "Gallery";

FramerZXPLkcF9z.defaultProps = {height: 490, width: 600};

addPropertyControls(FramerZXPLkcF9z, {variant: {options: ["VDF8QfLfh", "H74JxqKqp", "FSX7mqUMx"], optionTitles: ["Slide 1", "Slide 2", "Slide 3"], title: "Variant", type: ControlType.Enum}, yQxlx3dRA: {title: "Photo 1", type: ControlType.ResponsiveImage}, aXUMjUfzq: {title: "Photo 2", type: ControlType.ResponsiveImage}, xpBsZprVq: {title: "Photo 3", type: ControlType.ResponsiveImage}, h0RK_sc62: {defaultValue: "Snow", displayTextArea: false, title: "Item 1", type: ControlType.String}, k4U5vm2cy: {defaultValue: "Lake", displayTextArea: false, title: "Item 2", type: ControlType.String}, GnCinwaYh: {defaultValue: "Cabin", displayTextArea: false, title: "Item 3", type: ControlType.String}} as any)

addFonts(FramerZXPLkcF9z, [{explicitInter: true, fonts: []}, ...ControlFonts, ...PhotosFonts], {supportsExplicitInterCodegen: true})